<template>
  <div>
    <div v-if="readonly || disabled" class="button disabled">
      <span class="lang-item">
        <img class="flag-img" :src="currentLang.svgPath" />
        {{ $t(currentLang.name) }}
      </span>
    </div>
    <b-dropdown v-else ref="dd" v-model="localValue" aria-role="list" class="flags-dropdown" @active-change="positionDropdown" @change="onChange">
      <div slot="trigger" role="button" aria-haspopup="true" class="dropdown-trigger">
        <span v-if="!currentLang" class="button">{{ $t('common.selectLanguage') }}</span>
        <span v-if="currentLang" class="button">
          <span class="selected-label lang-item">
            <img class="flag-img" :src="currentLang.svgPath" />
            {{ $t(currentLang.name) }}
          </span>
          <a href="#" class="icon">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/chevron-down.svg')" />
          </a>
        </span>
      </div>

      <b-dropdown-item v-for="lang in langs" :key="lang.code" :value="lang.code" :readonly="readonly" :disabled="disabled" aria-role="listitem">
        <span class="lang-item">
          <img class="flag-img" :src="lang.svgPath" />
          {{ $t(lang.name) }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import Enums from '@/utils/enums';
import enSvg from '@/assets/icons/langs/en.svg';
import nlSvg from '@/assets/icons/langs/nl.svg';
import frSvg from '@/assets/icons/langs/fr.svg';
import deSvg from '@/assets/icons/langs/de.svg';
import esSvg from '@/assets/icons/langs/es.svg';
import itSvg from '@/assets/icons/langs/it.svg';

export default {
  name: 'FlagsDropdown',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },

  data() {
    const svgs = {
      en: enSvg,
      nl: nlSvg,
      fr: frSvg,
      de: deSvg,
      es: esSvg,
      it: itSvg
    };

    const langs = [];

    for (let langCode in Enums.LanguageMap) {
      langs.push({
        code: langCode,
        name: Enums.LanguageMap[langCode],
        svgPath: svgs[langCode]
      });
    }

    return {
      langs,
      localValue: ''
    };
  },

  computed: {
    currentLang() {
      return this.langs.find(item => item.code === this.value);
    }
  },
  watch: {
    value() {
      this.localValue = this.value;
    }
  },
  created() {
    if (this.value) {
      this.localValue = this.value;
    }
  },

  methods: {
    positionDropdown() {
      const mainEl = this.$refs.dd.$el;
      const mainRect = mainEl.getBoundingClientRect();

      const dropdownEl = mainEl.querySelector('.dropdown-menu');

      const wheight = window.innerHeight;

      if (wheight - mainRect.bottom >= mainRect.top) {
        dropdownEl.style.top = mainRect.bottom + 'px';
        dropdownEl.style.bottom = 'initial';
      } else {
        dropdownEl.style.top = 'initial';
        dropdownEl.style.bottom = wheight - mainRect.top + 'px';
      }

      dropdownEl.style.left = mainRect.left + 'px';
      dropdownEl.style.minWidth = 'initial';
    },
    onChange(val) {
      this.$emit('input', val);
    }
  }
};
</script>
<style lang="scss" scoped>
.flags-dropdown {
  ::v-deep .dropdown-menu {
    position: fixed;
  }
}

.lang-item {
  display: flex;
  align-items: center;
  margin-right: 16px;

  & > img {
    margin-right: 16px;
  }
}
</style>
